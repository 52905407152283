const codeInputRow = document.querySelectorAll('.code-input__row');
codeInputRow.forEach((el) => {
  const inputs = el.querySelectorAll('.input-field__input');

  inputs.forEach((input, index) => {
    const parentElem = input.closest('.input-field');

    if (index < inputs.length - 1) {
      const nextSibling = parentElem.nextElementSibling;
      const nextSiblingInput = nextSibling.querySelector('.input-field__input');
      input.addEventListener('keyup', (event) => {
        if ((event.keyCode === 13 && event.keyCode !== 8) || (input.value.length === 1 && event.keyCode !== 8)) {
          nextSiblingInput.focus();
        }
      });
    }

    if (index > 0) {
      const prevSibling = parentElem.previousElementSibling;
      const prevSiblingInput = prevSibling.querySelector('.input-field__input');
      input.addEventListener('keydown', (event) => {
        if (event.keyCode === 8 && input.value.length === 0) {
          prevSiblingInput.focus();
        }
      });
    }
  });
});
