// **********************************************************
// Device Slider
// **********************************************************

const deviceSliderElements = document.querySelectorAll('[data-slider="device-slider"]');

const deviceSliderOptions = {
  maxBackfaceHiddenSlides: 15,
  speed: 600,
  slidesPerView: 3,
  loop: true,
  centeredSlides: true,
  effect: 'coverflow', // 'cube', 'fade', 'coverflow',
  coverflowEffect: {
    rotate: 40, // Slide rotate in degrees
    stretch: 0, // Stretch space between slides (in px)
    depth: 100, // Depth offset in px (slides translate in Z axis)
    modifier: 1, // Effect multiple
    slideShadows: false, // Enables slides shadows
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  grabCursor: true,
  breakpoints: {
    // when window width is >= 320px
    768: {
      coverflowEffect: {
        rotate: 50, // Slide rotate in degrees
      },
    },
  },
};

deviceSliderElements.forEach((el) => {
  const deviceSliderParent = el.closest('.device-slider');
  const prevNavArrow = deviceSliderParent.querySelector('.swiper-button-prev');
  const nextNavArrow = deviceSliderParent.querySelector('.swiper-button-next');

  deviceSliderOptions.navigation.prevEl = prevNavArrow;
  deviceSliderOptions.navigation.nextEl = nextNavArrow;

  const deviceSlider = new Swiper(el, deviceSliderOptions);

  deviceSlider.on('slideChangeTransitionStart', () => {
    const activeItem = deviceSliderParent.querySelector('.swiper-slide-active');
    const activeColor = activeItem.getAttribute('data-color');
    deviceSliderParent.style.setProperty('--active-color', activeColor);
  });
});
